// const BASE_URL = "http://192.168.21.2:5001/node_api/";
// // const DEFAULT_KEY = 'de746c88-f891-4312-8e6e-9fa2a4dc8482'
// const DEFAULT_KEY = '8jjssh9JKH98hs%jh67%jh#$7:LK)797hjhkj%d%^'


//demo
const BASE_URL = "https://jessica.admin.iossmlm.com/node_api/";
const DEFAULT_KEY = '8jjssh9JKH98hs%jh67%jh#$7:LK)797hjhkj%d%^'

const PAYPAL_CLIENT_ID = "de746c88-f891-4312-8e6e-9fa2a4dc8482"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
